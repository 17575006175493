/**============================================================
*   [attentionBanner]
*   description : トップページの時限つきアテンションバナー
============================================================*/
document.addEventListener('DOMContentLoaded', () => {

  // 外部のJSONファイルのパス
  const jsonFilePath = "/assets/config/config.json";

  // バナーテキストコンテナID
  const containerTextId = "js-attention-text-container";

  // バナー画像コンテナID
  const containerImageId = "js-attention-banner-container";

  // JSONファイル読み込み
  (async () => {
    try {
      const res = await fetch(jsonFilePath);
      const jsonData = await res.json();
      createAndAppendTextBanner(containerTextId, jsonData.data);
      createAndAppendImageBanner(containerImageId, jsonData.data);
    } catch (e) {
    }
  })();

  // 期間による表示切り替え
  function isWithinDisplayPeriod(container, data) {
    const currentTime = Date.now();
    const startTime = new Date(data.startTime).getTime();
    const endTime = new Date(data.endTime).getTime();

    if (currentTime < startTime || currentTime > endTime) {
      container.style.display = "none";
      return false;
    }

    return true;
  }

  // テキストバナー用のDOMがある場合の処理
  function createAndAppendTextBanner(containerId, data) {
    const container = document.getElementById(containerId);
    if (!container) {
      return
    };

    if (!isWithinDisplayPeriod(container, data)) {
      return;
    }

    //アニメーションテキストバナー表示
    const attentionInner = document.querySelector(".js-attention-inner");
    const attentionClose = document.querySelector(".js-attention-close");

    if (container && attentionClose) {
      attentionClose.addEventListener('click', () => {
        container.classList.add('is-closed');
      });
    }

    container.classList.add('is-show');

    window.setTimeout(() => {
      attentionInner.classList.add('is-show--contents');
    }, 1500);

    window.setTimeout(() => {
      container.classList.remove('is-show');
    }, 5500);

    window.setTimeout(() => {
      attentionInner.classList.remove('is-show--contents');
    }, 6500);

    window.setTimeout(() => {
      container.classList.add('is-closed');
    }, 7000);

    // 時限バナーDOM要素作成・追加
    const linkElement = document.querySelector('#js-attention-inner a');
    linkElement.innerHTML = data.text;
  }

  // 画像バナー用のDOMがある場合の処理
  function createAndAppendImageBanner(containerId, data) {
    const container = document.getElementById(containerId);
    if (!container) {
      return
    };

    if (!isWithinDisplayPeriod(container, data)) {
      return;
    }

    //バナーエリアDOM要素作成・追加
    const imageLinkElement = document.querySelector('#js-attention-banner-container a');
    const imgElement = document.createElement("img");
    // ログイン状態に応じて画像を選択
    if (container.classList.contains('js-attention-banner-logged')) {
      imgElement.src = data.imgLogged;
    } else {
      imgElement.src = data.img;
    }
    imgElement.alt = data.alt;
    imgElement.setAttribute('width', '780');
    imageLinkElement.appendChild(imgElement);
  }

});
