/**============================================================
*   [headerLayout]
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  if(document.getElementById('js-header') !== null){
    //ヘッダーのfixed横スクロールの処理
    function fixedScroll(){
      document.getElementById('js-header').style.setProperty('--header-X', -window.scrollX+'px');
    }
    window.addEventListener('load', fixedScroll, true);
    window.addEventListener('scroll', fixedScroll, true);
    window.addEventListener('resize', fixedScroll, true);

    //ヘッダーの高さによってコンテンツ上部のpadding調整
    function setHeaderHeight(){
      document.getElementById('l-page-title').style.setProperty('--header-H', document.getElementById('js-header').offsetHeight+'px');
    }
    window.addEventListener('load', setHeaderHeight, true);
  }
});
