import Vue from 'vue/dist/vue.esm.js';
document.addEventListener('DOMContentLoaded', () => {
  /**============================================================
  *   [form] select
  *   description : selectのvalueが空の時にテキスト色を変更する処理を記述
  ============================================================*/
  if(document.querySelectorAll("select").length > 0){
    const selects = document.querySelectorAll('select');
    selects.forEach(select => {

      if (select.value !== '') {
        select.classList.remove('is-value-empty');
      }else{
        select.classList.add('is-value-empty');
      }

      select.addEventListener('change', (e) => {
        if (e.target.value !== '') {
          select.classList.remove('is-value-empty');
        }else{
          select.classList.add('is-value-empty');
        }
      })

    })
  }
  /**============================================================
  *   [form] login-button
  *   description : ログインボタンの活性・非活性を変更する処理を記述
  ============================================================*/
  new Vue({
    el: '#js-login',
    data: {
      field1: '',
      field2: '',
    },
    computed: {
      entered_all () {
        const required_fields = [
          this.field1,
          this.field2,
        ]
        return required_fields.indexOf('') === -1
      },
    },
  })

  /**============================================================
  *   [form] agree-checkbox
  *   description : チェックボックス後ボタンの活性・非活性を変更する処理を記述
  ============================================================*/
  new Vue({
    el: '#js-agree',
    data: {
      isChecked: true,
      leaveChecked: false,
    },
  })
  /**============================================================
  *   [form] login-button
  *   description : ログインボタンの活性・非活性を変更する処理を記述
  ============================================================*/
  const items = document.querySelectorAll('.c-form-toggle-pass-view');
  items.forEach((el) => {
    new Vue({
      el: el,
      data: {
        isVisible: false,
      },
      computed: {
        inputType: function () {
          return this.isVisible ? "text" : "password";
        }
      },
      methods: {
        onClick: function() {
          this.isVisible = !this.isVisible;
        }
      }
    })
  });

});
