import axios from 'axios';
/**============================================================
*   [modal]
*   description : 黒背景モーダルの処理を記述
============================================================*/
class modal {
  constructor(e,btn){
    this.$el = document.getElementById('js-modal');
    this.$body = document.getElementById('js-modal__body');
    this.isShow = false;
    this.href = '';
  }
  modalToggle(e,btn){
    const currentModal = this;
    if(modal.isShow){
      //close
      modal.isShow = false;
      currentModal.$el.classList.remove('is-show');
      currentModal.$body.innerHTML = '';
    }else{
      //open
      modal.isShow = true;

      //data-href取得して表示
      modal.href = btn.getAttribute('data-href');
      if(modal.href){
        currentModal.modalData(modal.href);
      }else{
        currentModal.$body.innerHTML = '';
      }
    }
  };
  modalData(url){
    const currentModal = this;
    axios.post(url)
    .then(data => {
      currentModal.$body.innerHTML = data.data;
      currentModal.$el.classList.add('is-show');
    })
    .catch(error => {
    });
  };
}
export default modal;

document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', e => {
    const jsModal = e.target.closest('.js-modal__button');
    if(jsModal){
      const jsModalContainer = jsModal.closest('.p-modal');
      if(jsModalContainer && jsModalContainer.getAttribute('id') !== 'js-modal') {
        jsModalContainer.classList.remove('is-show');
      }else{

        const modalButton = new modal(jsModal);
        modalButton.modalToggle(e,jsModal);
      }
    }
  }, true);
});
