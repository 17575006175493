import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);
/**============================================================
*   [お知らせ]
*   description : お知らせスライダーの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  if(document.querySelectorAll('.js-news-slider').length > 0){

    const swiperSlide = document.querySelectorAll('.js-news-slider');

    swiperSlide.forEach((slider, key) => {
      let swiperSlides = slider.getElementsByClassName('swiper-slide');
      let slidable = swiperSlides.length > slider.clientWidth/320;

      slider.classList.remove('swiper-disabled');
      slider.classList.add('swiper-'+ key);
      let mySwiper = new Swiper(slider, {
        spaceBetween: 20,
        slidesPerView: 'auto',
        loop: slidable,
        navigation: { //遷移ボタン
          nextEl: '.swiper-'+ key + '+ .swiper-button-prev + .swiper-button-next',
          prevEl: '.swiper-'+ key + '+ .swiper-button-prev',
        },
        watchOverflow: !slidable,
      });
    })
  }
});
